
import { defineComponent, ref, onMounted } from 'vue';
import router from '@/router';
import { CrmCustomerEditorModel, CrmCustomerLeadEditorModel } from '@/models/CrmCustomers';
import { IdName, SaveCrmCustomerLeadRequest, SaveCrmCustomerRequest, BussinesClientByNewOrderObject } from '@/models/Interfaces';
import CrmCustomerEditorModal from '@/components/CrmCustomers/CrmCustomerEditorModal.vue';
import { CrmCustomerLeadSaveChanges, CrmCustomerSaveChanges } from '@/models/Enums';
import swal from 'sweetalert2';
import { api } from '@/services/Api';
import { DateConstants } from '@/models/Constants';
import moment from 'moment';
import CrmCustomerForm from '@/components/CrmCustomers/CrmCustomerForm.vue';
import { getBirthdateString } from '@/helper';
import { generalStore } from '@/store';
import CrmCustomerLeadForm from '@/components/CrmCustomerLead/CrmCustomerLeadForm.vue';
import SearchCrmCustomersPopup from '@/components/CrmCustomerLead/SearchCrmCustomersPopup.vue';
export default defineComponent({
    props: {
        crmCustomerId: {
            type: String || null
        }
    },
    components: { CrmCustomerEditorModal, CrmCustomerLeadForm, CrmCustomerForm, SearchCrmCustomersPopup },
    name: 'CrmCustomerLeadCreate',
    async setup(props) {
        const crmCustomer = ref(new CrmCustomerEditorModel());
        const crmCustomerLead = ref(new CrmCustomerLeadEditorModel());
        const modalcrmCustomerEditor = ref<InstanceType<typeof CrmCustomerEditorModal>>();
        const modalSearchCrmCustomers = ref<InstanceType<typeof SearchCrmCustomersPopup>>();
        const crmCustomerForm = ref<InstanceType<typeof CrmCustomerForm>>();
        const crmCustomerLeadForm = ref<InstanceType<typeof CrmCustomerLeadForm>>();

        const leadTypes = ref<IdName[]>([]);
        const leadSources = ref<IdName[]>([]);
        const loaded = ref(false);
        const bussinesClients = ref<BussinesClientByNewOrderObject[]>([]);
        const calendarBirthdate = ref<Date | null>(null);
        const searchText = ref('');
        function back() {
            router.go(-1);
        }
        function openEditCrmCustomerModal() {
            if (!crmCustomer.value.id) {
                return;
            }
            const toEdit: CrmCustomerEditorModel = {
                id: crmCustomer.value.id,
                firstName: crmCustomer.value.firstName,
                lastName: crmCustomer.value.lastName,
                email: crmCustomer.value.email,
                mobile: crmCustomer.value.mobile,
                birthdate: crmCustomer.value.birthdate,
                businessClientId: crmCustomer.value.businessClientId,
                businessClientName: crmCustomer.value.businessClientName
            };
            modalcrmCustomerEditor.value?.open(toEdit);
        }
        function updatecrmCustomerDetails(crmCustomerReq: CrmCustomerEditorModel) {
            crmCustomer.value.firstName = crmCustomerReq.firstName;
            crmCustomer.value.lastName = crmCustomerReq.lastName;
            crmCustomer.value.email = crmCustomerReq.email;
            crmCustomer.value.mobile = crmCustomerReq.mobile;
            crmCustomer.value.birthdate = crmCustomerReq.birthdate;
            crmCustomer.value.businessClientId = crmCustomerReq.businessClientId;
            crmCustomer.value.businessClientName = crmCustomerReq.businessClientName;
        }
        async function onSave() {
            swal.showLoading();
            const customerReq: SaveCrmCustomerRequest = {
                id: crmCustomer.value.id,
                firstName: crmCustomer.value.firstName,
                lastName: crmCustomer.value.lastName,
                email: crmCustomer.value.email,
                mobile: crmCustomer.value.mobile,
                birthdate: getBirthdateString(calendarBirthdate.value),
                businessClientId: crmCustomer.value.businessClientId,
                remarks: '',
                changes: CrmCustomerSaveChanges.AllExcludeRemarks
            };
            let crmCustomerIdReq = null;
            if (props.crmCustomerId) {
                crmCustomerIdReq = props.crmCustomerId;
            } else if (crmCustomerLead.value.crmCustomerId) {
                crmCustomerIdReq = crmCustomerLead.value.crmCustomerId;
            }
            const leadReq: SaveCrmCustomerLeadRequest = {
                id: null,
                crmCustomerId: crmCustomerIdReq,
                description: crmCustomerLead.value.description,
                leadSourceId: crmCustomerLead.value.leadSourceId,
                leadTypeId: crmCustomerLead.value.leadTypeId,
                active: crmCustomerLead.value.active,
                numberOfGuests: Number(crmCustomerLead.value.numberOfGuests),
                remarks: crmCustomerLead.value.remarks,
                status: crmCustomerLead.value.status,
                changes: CrmCustomerLeadSaveChanges.NewEntity
            };
            const apiResponse = await api.saveCrmCustomerCreateLeadCreate(customerReq, leadReq);
            if (apiResponse.validationErrors?.length) {
                crmCustomerForm.value?.setValidator(apiResponse.validationErrors);
                crmCustomerLeadForm.value?.setValidator(apiResponse.validationErrors);
                const html = apiResponse.validationErrors
                    .map(x => {
                        return `<div>${x.key}: ${x.msg}</div>`;
                    })
                    .join('');
                swal.fire({
                    icon: 'error',
                    html: html
                });
                return;
            }
            if (apiResponse.errorMessage || !apiResponse.data) {
                swal.fire({
                    icon: 'error',
                    text: apiResponse.errorMessage || 'No data'
                });
                return;
            }
            swal.close();
            router.push({
                name: 'crm-customer-lead-details',
                params: { crmCustomerId: apiResponse.data.crmCustomerId, crmCustomerLeadId: apiResponse.data.crmCustomerLeadId }
            });
        }
        const submitForm = async (event: any) => {
            crmCustomerForm.value?.clearValidator();
            crmCustomerLeadForm.value?.clearValidator();
            event.target.classList.add('was-validated');
            if (event.target.checkValidity() === false) {
                event.preventDefault();
                event.stopPropagation();
                return;
            }
            await onSave();
        };

        function birthDateChanged(event: Date) {
            calendarBirthdate.value = event;
            crmCustomer.value.birthdate = moment(event).format(DateConstants.DATE_FORMAT_SHOW);
        }
        function openSearchCrmCustomers() {
            modalSearchCrmCustomers.value?.open();
        }
        function selectCustomer(customer: CrmCustomerEditorModel) {
            if (!customer.id) {
                return;
            }
            crmCustomer.value = customer;
            router.push({ name: 'crm-customer-lead-create', params: { crmCustomerId: customer.id } });
        }
        function changeSearchText(test: string) {
            searchText.value = test;
        }
        const onMountedCall = async () => {
            swal.showLoading();
            if (props.crmCustomerId) {
                const responseCrmCustomer = await api.getCrmCustomerShortInfo(props.crmCustomerId);
                if (responseCrmCustomer?.errorMessage || !responseCrmCustomer.data) {
                    swal.fire({
                        icon: 'error',
                        text: responseCrmCustomer.errorMessage || 'error'
                    });
                    return;
                }
                crmCustomer.value = responseCrmCustomer.data.crmCustomer;
            }
            const response = await api.GetCrmCustomerLeadTypes();
            if (response?.errorMessage || !response.data) {
                swal.fire({
                    icon: 'error',
                    text: response.errorMessage || 'error'
                });
                return null;
            }
            leadTypes.value = response.data.types;
            const response2 = await api.GetCrmCustomerLeadSources();
            if (response2?.errorMessage || !response2?.data) {
                swal.fire({
                    icon: 'error',
                    text: response2.errorMessage || 'error'
                });
                return null;
            }
            leadSources.value = response2.data.sources;
            bussinesClients.value = await generalStore.dispatch('loadBusinessClients');
            loaded.value = true;
            swal.close();
        };
        onMounted(onMountedCall);
        return {
            back,
            submitForm,
            crmCustomer,
            openEditCrmCustomerModal,
            updatecrmCustomerDetails,
            modalcrmCustomerEditor,
            crmCustomerLead,
            leadTypes,
            leadSources,
            loaded,
            bussinesClients,
            calendarBirthdate,
            birthDateChanged,
            searchText,
            openSearchCrmCustomers,
            modalSearchCrmCustomers,
            selectCustomer,
            changeSearchText,
            crmCustomerForm,
            crmCustomerLeadForm
        };
    }
});
