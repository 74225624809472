
import { defineComponent, ref, toRef } from 'vue';
import OverlayModal from '@/components/Calendar/OverlayModal.vue';
import { api } from '@/services/Api';
import swal from 'sweetalert2';
import { CrmCustomerEditorModel } from '@/models/CrmCustomers';

export default defineComponent({
    props: {
        searchText: {
            type: String,
            required: true
        }
    },
    components: {
        OverlayModal
    },
    name: 'SearchCrmCustomersPopup',
    async setup(props, { emit }) {
        const isOpen = ref(false);
        const searchTextRef = toRef(props, 'searchText');
        const customers = ref<CrmCustomerEditorModel[]>([]);
        async function searchCrmCustomers() {
            swal.showLoading();
            const response = await api.getCrmCustomersForLeadCreation(searchTextRef.value);
            if (response.errorMessage || !response.data) {
                swal.fire({
                    icon: 'error',
                    text: response.errorMessage || 'No data'
                });
                return;
            }
            customers.value = response.data.crmCustomers;
            swal.close();
        }
        async function open() {
            isOpen.value = true;
            await searchCrmCustomers();
        }
        function close() {
            isOpen.value = false;
        }
        async function showAll() {
            searchTextRef.value = '';
            await searchCrmCustomers();
        }
        function selectCustomer(customer: CrmCustomerEditorModel) {
            emit('selectCustomer', customer);
            close();
        }
        function changeSearchText(e: any) {
            emit('changeSearchText', e.target.value);
        }
        return {
            isOpen,
            open,
            close,
            searchTextRef,
            showAll,
            searchCrmCustomers,
            customers,
            selectCustomer,
            changeSearchText
        };
    }
});
